<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="headdepartments"
          :search="search"
          title="Kadis"
          subtitle="Halaman untuk menambahkan Kadis"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @show-image="showImage"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="880"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!valueCheck || checkProvince === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Kadis': 'Edit Kadis'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-tabs
          v-model="tab"
          show-arrows
          fixed-tabs
        >
          <v-tab
            v-for="tabItem in tabs"
            :key="tabItem.title"
          >
            <span>{{ tabItem.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-0"
        >
          <v-tab-item eager>
            <data-required
              ref="requiredData"
              :edit-uuid.sync="editUuid"
              @change-valid="changeValid"
            ></data-required>
          </v-tab-item>
          <v-tab-item eager>
            <data-other
              ref="otherData"
              :edit-uuid.sync="editUuid"
            ></data-other>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <!-- <template v-slot:footer>
        <v-btn
          v-if="dialog == 'add'"
          color="primary"
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="add()"
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog == 'edit'"
          color="primary"
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="update(editUuid)"
        >
          Update
        </v-btn>

        <v-btn
          color="primary"
          outlined
          large
          class="mx-2"
          @click="modalDialog=false && editUuid == ''"
        >
          Batal
        </v-btn>
      </template> -->
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Kadis"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="headdepartment.photo"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import DataOther from './DataOther.vue'
import DataRequired from './DataRequired.vue'

export default {
  name: 'KadisAdmin',
  components: {
    DataTablePagination,
    ModalDialog,
    DataRequired,
    SkeletonLoaderTable,
    MainCard,

    DataOther,
    ImageOverlay,
    ConfirmDialog,
  },
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Data Wajib' }, { title: 'Data Lainnya (Optional)' }],
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },
      width: 600,
      page: 1,
      imageOverlay: false,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      totalItems: 1,
      totalPages: 0,
      search: '',
      dialog: '',
      headdepartments: [],
      headdepartment: {
        uuid: '',
        photo: null,
        username: '',
        password: '',
        name: '',
        province_uuid: '',
        regencies_uuid: '',
        nip: '',
        nik: '',
        first_degree: '',
        last_degree: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        religion: '',
        phone: '',
        date_of_work: '',
        formValid: false,
      },
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama', value: 'name' },
        { text: 'Username', value: 'username' },
        { text: 'Photo', value: 'photo' },
        { text: 'Kadis', value: 'type' },
        { text: 'Aksi', value: 'actions' },
      ],
      formData: [],
      editUuid: '',
      deleteUuid: '',
      filterQuery: {
        name: '',
      },
      valueCheck: false,
      checkProvince: null,
    }
  },
  watch: {
    dialog: {
      handler() {
        this.valueCheck = this.$refs.requiredData.formValid
      },
    },
    page: {
      handler() {
        this.listHeaddepartment(this.filterQuery)
      },
    },
  },
  mounted() {
    this.listHeaddepartment()
  },
  methods: {
    changeValid() {
      this.valueCheck = this.$refs.requiredData.$data.formValid
      this.checkProvince = this.$refs.requiredData.$data.headdepartment.province_uuid?.uuid ?? null
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    resetForm() {
      this.$refs.requiredData.resetData()
      this.$refs.otherData.resetData()
    },

    async listHeaddepartment(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('headdepartment', { ...params, page: this.page }).then(
        ({ data }) => {
          this.headdepartments = data.data.map((headdepartments, index) => ({
            ...headdepartments,
            index: index + 1,
          }))
          console.log(data.data)
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    showFormAdd() {
      this.dialog = 'add'
      this.editUuid = ''
      this.resetForm()
      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true

      const provinceUuid = this.$refs.requiredData.$data.headdepartment.province_uuid?.uuid ?? ''
      const regenciesUuid = this.$refs.requiredData.$data.headdepartment.regencies_uuid?.uuid ?? ''

      this.$refs.requiredData.$data.headdepartment.province_uuid = provinceUuid
      this.$refs.requiredData.$data.headdepartment.regencies_uuid = regenciesUuid

      if (this.$refs.requiredData.$data.headdepartment.province_uuid === '') {
        delete this.$refs.requiredData.$data.headdepartment.province_uuid
      }
      if (this.$refs.requiredData.$data.headdepartment.regencies_uuid === '') {
        delete this.$refs.requiredData.$data.headdepartment.regencies_uuid
      }

      this.formData = new FormData()

      Object.entries(this.$refs.requiredData.$data.headdepartment).forEach(([key, item]) => {
        this.formData.append(key, item)
      })

      Object.entries(this.$refs.otherData.$data.headdepartment).forEach(([key, item]) => {
        if (item === null) {
          item = ''
        }
        this.formData.append(key, item)
        if (item === '' || item === null || typeof item === 'undefined') {
          this.formData.delete(key)
        }
      })

      await this.$services.ApiServices.add('headdepartment', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.resetForm()
          this.listHeaddepartment(this.filterQuery)
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    showFormEdit(id) {
      this.dialog = 'edit'
      this.edit(id)
      this.modalDialog = true
    },

    async edit(uuid) {
      this.editUuid = uuid
    },

    async update() {
      this.isLoadingButton = true

      const provinceUuid = this.$refs.requiredData.$data.headdepartment.province_uuid?.uuid ?? ''
      const regenciesUuid = this.$refs.requiredData.$data.headdepartment.regencies_uuid?.uuid ?? ''

      this.$refs.requiredData.$data.headdepartment.province_uuid = provinceUuid
      this.$refs.requiredData.$data.headdepartment.regencies_uuid = regenciesUuid

      if (this.$refs.requiredData.$data.headdepartment.province_uuid === '') {
        delete this.$refs.requiredData.$data.headdepartment.province_uuid
      }
      if (this.$refs.requiredData.$data.headdepartment.regencies_uuid === '') {
        delete this.$refs.requiredData.$data.headdepartment.regencies_uuid
      }

      this.formData = new FormData()

      Object.entries(this.$refs.requiredData.$data.headdepartment).forEach(([key, item]) => {
        this.formData.append(key, item)
        console.log(key)
        if (item === '' || item === null || typeof item === 'undefined') {
          this.formData.delete(key)
        }
      })

      Object.entries(this.$refs.otherData.$data.headdepartment).forEach(([key, item]) => {
        if (item === null) {
          item = ''
        }

        this.formData.append(key, item)

        if (item === '' || item === null || typeof item === 'undefined') {
          this.formData.delete(key)
        }
      })

      const oldPhoto = this.$refs.otherData.$data.headdepartment.photo
      if (typeof oldPhoto && typeof this.formData.get('photo') === 'string') {
        this.formData.delete('photo')
      }

      await this.$services.ApiServices.update('headdepartment', this.formData, this.editUuid).then(
        ({ data }) => {
          this.editUuid = ''
          this.resetForm()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.modalDialog = false
          this.listHeaddepartment(this.filterQuery)
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )

      this.isLoadingButton = false
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.deleteUuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('headdepartment', this.deleteUuid).then(({ data }) => {
        this.listHeaddepartment(this.filterQuery)
        this.showSnackbar({
          text: data.result.message,
          color: 'error',
        })
      })
      this.isLoadingButton = false
      this.confirmDialog = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(search) {
      this.filterQuery.name = search
      this.page = 1
      this.listHeaddepartment(this.filterQuery)
    },

    async showImage(uuid) {
      await this.$services.ApiServices.get('headdepartment', uuid).then(
        ({ data }) => {
          this.headdepartment = data.data
          this.headdepartment.photo = data.data.photo
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },
  },
}
</script>

<style>
</style>
